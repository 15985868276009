import React, { Fragment, useEffect, useState } from "react";
import "./styles.css";

import Clock from "../../../assets/svg/Clock.svg";
import PicPay from "../../../assets/svg/picpay-1 1.svg";
import ValidTicket from "../../../assets/svg/Valid Ticket.svg";
import InvalidTicket from "../../../assets/svg/Invalid Ticket.svg";
import Stopwatch from "../../../assets/svg/stopwatch.svg";
import { Card, Image } from "react-bootstrap";
import DownloadApp from "../../../components/DownloadApp";
import { useAuth } from "../../../hooks/useAuth";
import { useParams, useHistory } from "react-router-dom";
import QRCodeSection from "../../../components/QRCodeSection";
import { Trans, useTranslation } from "react-i18next";
import PixSection from "./components/PixSection";
import PicpaySection from "./components/PicpaySection";
import ConcludedSection from "./components/ConcludedSection";
import ErrorSection from "./components/ErrorSection";
import InstructionsSection from "./components/InstructionsSection";
import TipSection from "./components/TipSection";
import ProcessingSection from "./components/ProcessingSection";
import BoletoSection from "./components/BoletoSection";
import PixLogo from "../../../assets/svg/pix_logo.svg";
import OrderValueSection from "./components/OrderValueSection";
import NewApi from "../../../services/new-api";
import MaskHelper from "../../../Helpers/mask";
import DateHelper from "../../../Helpers/date";
import Swal from "sweetalert2";
import NewLoading from "../../../components/NewLoading";
import PixAccordion from "./PixAccordion";
import ConcludedInformation from "./components/ConcludedInformation";
import DigitalCardExample from "./components/DigitalCardExample";
import Loader from "../../../components/Loader";

const isWhitelabel = !!Number(process.env.REACT_APP_IS_WHITELABEL);

export default function ResultCheckoutPage() {
  const { user } = useAuth();
  const { status, token } = useParams();
  const { t } = useTranslation();
  const [isCopy, setIsCopy] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [resultData, setResultData] = useState({});
  const [hasError, setHasError] = useState(false)
  const history = useHistory();
  const api = new NewApi();

  const isDigitalCardEnabled = !!Number(resultData.digitalCardEnabled);

  const handleRedirect = (action, openTab = false) => {
    const urls = {
      picpay: resultData.picpayUrl,
      myTickets: "/meus-ingressos",
      myOrders: "/meus-pedidos",
      tryAgain: `/eventos/${localStorage.getItem("slug")}`,
      boleto: resultData.urlBillet,
      downloadApp: "https://zigpay.page.link/download",
      recharge: "https://rc.zig.fun/cashless-digital/?utm_campaign=fluxo360&utm_source=marketplace-zig&utm_medium=checkout-btn"
    };

    openTab ? window.open(urls[action]) : window.location.href = urls[action];
  };

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };

  const handleCopyPix = () => {
    navigator.clipboard.writeText(resultData.urlBillet);
    setIsCopy(true);

    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
  };

  const getPendingIcon = () => {
    if (resultData.type === "pix") {
      return PixLogo;
    }

    if (resultData.type === "picpay") {
      return PicPay;
    }

    return Clock;
  };

  const checkoutStatus = {
    pending: {
      icon: getPendingIcon(),
      title: `CheckoutStatus.pending.title${resultData.type || ""}`,
    },
    processing: {
      icon: Clock,
      title: "CheckoutStatus.processing.title",
    },
    concluded: {
      icon: ValidTicket,
      title: "CheckoutStatus.concluded.title",
    },
    error: {
      icon: InvalidTicket,
      title: "CheckoutStatus.error.title",
    },
    timeout: {
      icon: Stopwatch,
      title: "CheckoutStatus.timeout.title",
    },
  };

  const getOrderStatus = async () => {
    const { status, expire_date } = await api
      .get(`orders/${token}?onlyOrder=true`)
      .then(({ data }) => data);
    return { status, expire_date };
  };

  const clearSessionAndCloseTo = (route) => {
    setLoading(false)
    history.push(`/status/${token}/checkout/${route}`);
  };

  const handleCheckPayment = async () => {
    setLoading(true)
    const { status, expire_date } = await getOrderStatus();

    if (["finalizado", "expirado"].includes(status) || new Date(expire_date) < new Date()) {
      if (status === "finalizado") {
        clearSessionAndCloseTo("concluded");
      } else {
        clearSessionAndCloseTo("timeout");
      }
    } else {
      setLoading(false)
      Swal.fire({
        imageUrl:
          "http://cdn.mcauto-images-production.sendgrid.net/c751db91e17f1b66/ccb11ef7-c7ef-4ca5-86d2-633d15bef350/65x65.png",
        imageHeight: 65,
        imageWidth: 65,
        padding: 24,
        imageAlt: "Clock icon",
        confirmButtonText: "Entendi",
        customClass: {
          confirmButton: "button-contained primary-contained",
          popup: "error-payment-container",
          title: "error-payment-title",
          htmlContainer: "error-payment-text",
          actions: "error-payment-actions",
        },
        buttonsStyling: false,
        confirmButtonColor: "#1C70E9",
        title: t("CheckoutStatus.popUps.paymentUnidentified.title"),
        html: `
        <div class="d-flex justify-content-center align-items-center text-center flex-column">
          <p class="mb-2">
          ${t("CheckoutStatus.popUps.paymentUnidentified.description")}
          </p>
        </div>
        `,
      });
    }
  };

  useEffect(() => {
    let sessionStorageToken;

    if (!token) {
      sessionStorageToken = sessionStorage.getItem('order_token');
    }

    api.get(`orders/${token || sessionStorageToken}/payment-details`).then(({ data }) => {
      setResultData(data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      setHasError(true)
      if (error.response && error.response.status === 404) {
        Swal.fire({
          icon: "error",
          title: t("CheckoutStatus.popUps.notFound.title"),
          text: t("CheckoutStatus.popUps.notFound.description"),
          didClose: () => {
            history.push('/');
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("CheckoutStatus.popUps.unauthorized.title"),
          text: t("CheckoutStatus.popUps.unauthorized.description"),
          didClose: () => {
            history.push('/');
          },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    window.scrollTo(0, 0);

    if (status === 'pending') {
      const callOrderStatus = async () => {
        const { status, expire_date } = await getOrderStatus();
        return { orderStatus: status, expire_date };
      };

      const interval = setInterval(async () => {
        const { orderStatus, expire_date } = await callOrderStatus();

        if (orderStatus === "finalizado") {
          setLoading(true)
          clearSessionAndCloseTo("concluded");
        }
        if (orderStatus === "expirado" || new Date(expire_date) < new Date()) {
          setLoading(true)
          clearSessionAndCloseTo("timeout");
        }
      }, 60000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const checkoutButtons = {
    pix: (
      <Fragment>
        <PixSection
          isCopy={isCopy}
          handleCopy={handleCopyPix}
          handlePayment={handleCheckPayment}
        />
      </Fragment>
    ),
    picpay: (
      <Fragment>
        <PicpaySection code={resultData.urlBillet} handleRedirect={handleRedirect} handlePayment={handleCheckPayment} />
      </Fragment>
    ),
    concluded: (
      <Fragment>
        <ConcludedSection handleRedirect={handleRedirect} isWhitelabel={isWhitelabel} digitalCardEnabled={isDigitalCardEnabled} handleOpenModal={handleOpenModal} />
      </Fragment>
    ),
    processing: (
      <Fragment>
        <ProcessingSection handleRedirect={handleRedirect} status={status} />
      </Fragment>
    ),
    boleto: (
      <Fragment>
        <BoletoSection handleRedirect={handleRedirect} />
      </Fragment>
    ),
  };

  const isPixOrPicPay = ["pix", "picpay"].includes(resultData.type);

  const modalTitle = () => {
    if (!resultData.type) {
      if (status === "processing") {
        return t("CheckoutStatus.processing.title", {
          firstName: user.first_name,
          token: token.toUpperCase(),
        });
      }

      if (status === "concluded") {
        if (!isWhitelabel && isDigitalCardEnabled) {
          return t("CheckoutStatus.concluded.titleDigitalCard");
        }

        return t("CheckoutStatus.concluded.title", {
          firstName: user.first_name,
          token: token.toUpperCase(),
        });
      }
    }

    return t(checkoutStatus[status].title, {
      firstName: user.first_name,
      token: token.toUpperCase(),
    });

  }

  const modalSubTitle = () => {
    if (status === "pending") {
      return resultData.type ? <Trans
        i18nKey={`CheckoutStatus.pending.subtitle.${resultData.type || ""}`}
        components={{ strong: <strong /> }}
      /> : null
    }

    if (status === "concluded") {
      if (!isWhitelabel && isDigitalCardEnabled) {
        return t("CheckoutStatus.concluded.subtitleDigitalCard");
      }

      return t(`CheckoutStatus.${status}.subtitle`);
    }

    return t(`CheckoutStatus.${status}.subtitle`)

  }

  return loading ? (
    <Fragment>
      <div className="loading-container"></div>
      <Loader></Loader>
    </Fragment>
  ) : (
    !hasError && <Fragment>
      <NewLoading
        showModal={loading}
        title={t("Loading.verifying")}
        subtitle={t("Loading.mayTakeAWhile")}
      />
      <div className="d-flex justify-content-center mt-3">
        <div className="d-flex justify-content-center flex-column mt-3 main-card">
          <Card className={`checkout-card ${status}`}>
            <div id="checkout-content">
              <Card.Body className="d-flex flex-column flex-md-row gap-4 md-gap-0">
                <div className={`d-flex flex-column gap-4 ${status === "concluded" && isDigitalCardEnabled ? "col col-md-6" : ""}`}>
                  <Card.Title className="d-flex flex-column gap-4 m-0">
                    <div className="d-flex justify-content-center icon-header">
                      <Image src={checkoutStatus[status].icon} alt="Clock" />
                    </div>

                    {status === "concluded" && isDigitalCardEnabled && <p className={`checkout-text ${status}-text`}>{t("CheckoutStatus.concluded.ticketsAvailable")}</p>}

                    <h4 className={`checkout-title ${status}-title`}>{modalTitle()}</h4>

                    {!["concluded", "timeout", "error"].includes(status) && !!isPixOrPicPay && !!resultData.orderValue && <OrderValueSection
                      status={status}
                      type={resultData.type}
                      value={MaskHelper.numberToLocaleCurrency(
                        Number(resultData.orderValue)
                      )}
                      code={resultData.urlBillet}
                    />}

                    {status !== "pending" && <p className={`checkout-text ${status}-text`}>{modalSubTitle()}</p>}
                  </Card.Title>

                  <div className="d-flex flex-column justify-content-center align-items-center gap-4">

                    {!["error", "concluded", "timeout"].includes(status) && checkoutButtons[resultData.type || status]}

                    {status === "concluded" && checkoutButtons[status]}

                    {!["timeout", "concluded", "error"].includes(status) && isPixOrPicPay ? (
                      <p className="checkout-text">
                        <Trans
                          i18nKey={`CheckoutStatus.pending.instructions.${resultData.type}`}
                          values={{
                            datetime: DateHelper.addMinutesToCurrentTime(
                              60,
                              resultData.paymentDate
                            ),
                          }}
                          components={{ strong: <strong /> }}
                        />
                      </p>
                    ) : (
                      <>
                        {status === "pending" && (
                          <>
                            <InstructionsSection
                              status={status}
                              type={resultData.type}
                            />

                            <TipSection status={status} type={resultData.type} />
                          </>
                        )}
                      </>
                    )}

                    <ErrorSection handleRedirect={handleRedirect} status={status} />

                    {!isDigitalCardEnabled && status === "concluded" && <div>
                      <ConcludedInformation email={user.email} order={token} />
                    </div>}
                  </div>
                </div>

                {status === "concluded" && !isWhitelabel && isDigitalCardEnabled &&
                  <div className="col col-md-6 d-flex justify-content-center flex-column">
                    <DigitalCardExample />
                  </div>}
              </Card.Body>
            </div>
          </Card>

          {isDigitalCardEnabled && status === "concluded" && <Card className="checkout-card">
            <Card.Body>
              <ConcludedInformation email={user.email} order={token} className="py-2" />
            </Card.Body>
          </Card>}

          {status === "pending" && isPixOrPicPay && (
            <div className="d-flex justify-content-center flex-column">
              {resultData.type === "pix" && <PixAccordion />}

              <Card className={`checkout-card`}>
                <Card.Body id="checkout-card">
                  <div className="d-flex justify-content-center flex-column align-items-center pix-instructions text-center gap-3">
                    <h4>
                      {t("CheckoutStatus.common.qrCode")}
                    </h4>
                    <QRCodeSection
                      code={resultData.urlBillet}
                      isPicpay={resultData.picpayUrl}
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
      </div>
      {!isWhitelabel && (
        <div className="m-3">
          <DownloadApp isOpen={isOpen} handleOpenModal={handleOpenModal} />
        </div>
      )}
    </Fragment>
  )



}
