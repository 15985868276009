import React from 'react';
import { ReactComponent as PaymentIcon } from '../../assets/svg/payment_refused.svg'
import { useTranslation } from 'react-i18next';
import CloseButton from "../../assets/svg/generic_close.svg";
import './style.css';

export default function PaymentError({ onClose }) {
  const { t } = useTranslation();

  return (
    <div className="payment-modal-overlay">
      <div className="payment-modal-body">
        <img
          className="close-button"
          src={CloseButton}
          alt="close icon"
          width={16}
          height={16}
          aria-hidden="true"
          onClick={onClose}
        />
        <div className="payment-modal-header">
          <PaymentIcon width={64} height={64} />
          <h2>{t('Checkout.paymentRefused')}</h2>
        </div>
        <div className="text-section">
          <p>{t('Checkout.tryOtherPaymentMethod')}</p>
          <p>{t('Checkout.refundWillBeMade')}</p>
        </div>
        <button className="retry-button" onClick={onClose}>
          {t('Checkout.tryAgain')}
        </button>
      </div>
    </div>
  );
}
